export const GET_UNIT_CATEGORIES_LIST = "[UNIT_CATEGORIES] List Get";
export const GET_UNIT_CATEGORIES_LIST_SUCCESS = "[UNIT_CATEGORIES] List Get Success";
export const GET_UNIT_CATEGORIES_LIST_FAIL = "[UNIT_CATEGORIES] List Get Fail";

export const GET_UNIT_CATEGORIES_PAGINATION = "[UNIT_CATEGORIES] Pagination Get";
export const GET_UNIT_CATEGORIES_PAGINATION_SUCCESS = "[UNIT_CATEGORIES] Pagination Get Success";
export const GET_UNIT_CATEGORIES_PAGINATION_FAIL = "[UNIT_CATEGORIES] Pagination Get Fail";

export const GET_UNIT_CATEGORIES_COLLECTION = "[UNIT_CATEGORIES] Collection Get";
export const GET_UNIT_CATEGORIES_COLLECTION_SUCCESS = "[UNIT_CATEGORIES] Collection Get Success";
export const GET_UNIT_CATEGORIES_COLLECTION_FAIL = "[UNIT_CATEGORIES] Collection Get Fail";

export const GET_UNIT_CATEGORIES_SINGLE = "[UNIT_CATEGORIES] Single Get";
export const GET_UNIT_CATEGORIES_SINGLE_SUCCESS = "[UNIT_CATEGORIES] Single Get Success";
export const GET_UNIT_CATEGORIES_SINGLE_FAIL = "[UNIT_CATEGORIES] Single Get Fail";

export const CREATE_UNIT_CATEGORIES = "[UNIT_CATEGORIES] Create";
export const CREATE_UNIT_CATEGORIES_SUCCESS = "[UNIT_CATEGORIES] Create Success";
export const CREATE_UNIT_CATEGORIES_FAIL = "[UNIT_CATEGORIES] Create Fail";
export const CREATE_UNIT_CATEGORIES_RESET = "[UNIT_CATEGORIES] Create Reset";

export const UPDATE_UNIT_CATEGORIES = "[UNIT_CATEGORIES] Update";
export const UPDATE_UNIT_CATEGORIES_SUCCESS = "[UNIT_CATEGORIES] Update Success";
export const UPDATE_UNIT_CATEGORIES_FAIL = "[UNIT_CATEGORIES] Update Fail";
export const UPDATE_UNIT_CATEGORIES_RESET = "[UNIT_CATEGORIES] Update Reset";

export const REMOVE_UNIT_CATEGORIES = "[UNIT_CATEGORIES] Remove";
export const REMOVE_UNIT_CATEGORIES_SUCCESS = "[UNIT_CATEGORIES] Remove Success";
export const REMOVE_UNIT_CATEGORIES_FAIL = "[UNIT_CATEGORIES] Remove Fail";

export const GET_UNIT_CATEGORY_PRICE_PER_GUEST_CONFIG = "[UNIT_CATEGORIES] Price Per Guest Config Get";
export const GET_UNIT_CATEGORY_PRICE_PER_GUEST_CONFIG_SUCCESS = "[UNIT_CATEGORIES] Price Per Guest Config Get Success";
export const GET_UNIT_CATEGORY_PRICE_PER_GUEST_CONFIG_FAIL = "[UNIT_CATEGORIES] Price Per Guest Config Get Fail";

export const UPDATE_UNIT_CATEGORY_PRICE_PER_GUEST_CONFIG = "[UNIT_CATEGORIES] Price Per Guest Config Update";
export const UPDATE_UNIT_CATEGORY_PRICE_PER_GUEST_CONFIG_SUCCESS = "[UNIT_CATEGORIES] Price Per Guest Config Update Success";
export const UPDATE_UNIT_CATEGORY_PRICE_PER_GUEST_CONFIG_FAIL = "[UNIT_CATEGORIES] Price Per Guest Config Update Fail";
export const UPDATE_UNIT_CATEGORY_PRICE_PER_GUEST_CONFIG_RESET = "[UNIT_CATEGORIES] Price Per Guest Config Update Reset";

export const PULL_UNIT_CATEGORY_IMAGES = "[UNIT_CATEGORIES] Pull Octorate Images";
export const PULL_UNIT_CATEGORY_IMAGES_SUCCESS = "[UNIT_CATEGORIES] Pull Images Octorate Success";
export const PULL_UNIT_CATEGORY_IMAGES_FAIL = "[UNIT_CATEGORIES] Pull Images Octorate Fail";